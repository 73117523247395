// Nav.jsx
import React from "react";
import { useTranslation } from "react-i18next";

const Nav = ({ isActive }) => { // Dil değiştirme işlevselliğini prop olarak alın
  const { t } = useTranslation();
  return (
    <nav>
      <ul className="flex gap-x-10">
        {t("navItems", { returnObjects: true }).map((item, index) => {
          const { href, name } = item;
          return (
            <li className="relative group" key={index}>
              <a
                className={`${
                  isActive
                    ? "text-accent hover:text-accentHover"
                    : "text-gray-300 hover:text-white"
                }  transition  duration-300`}
                href={href}
              >
                {" "}
                {name}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Nav;
