import React from 'react';
import { useTranslation } from 'react-i18next';
import Cards from './Cards';

const Problem = () => {
  const { t } = useTranslation();


  return (
    <section id='problem' className='section mt-12 sm:mt-60 lg:mt-80'>
      <div className='container mx-auto'>
        <div className='flex flex-col itesm-center lg:flex-row mb-10 lg:mb-20'>
          <h2 className='section-title' data-aos='fade-up' data-aos-offset='200' data-aos-delay='300'>{t("problem.title")}</h2>
          <p 
            data-aos='fade-up' data-aos-offset='200' data-aos-delay='400'
            className='lead lg:max-w-[350px] text-darkSecondary dark:text-light'>{t("problem.subtitle")}</p>
        </div>
        <Cards />
      </div>
    </section>
  );
};

export default Problem;
