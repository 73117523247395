import React from "react";
import { features } from "../data";
import { useTranslation } from "react-i18next";

const Feature2 = () => {
  const { feature2 } = features;
  const { btnIcon, image } = feature2;
  const { t } = useTranslation();
  return (
    <section className="section">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-center lg:gap-x-[30px]">
          <div
            className="flex-1 order-2 lg:order-1"
            data-aos="fade-right"
            data-aos-offset="100"
          >
            <img src={image} className="" alt="" />
          </div>
          <div
            className="flex-1 order-1 lg:order-2"
            data-aos="fade-left"
            data-aos-offset="100"
          >
            <div className="pretitle text-darkSecondary dark:text-light">
              {t("feature2.pretitle")}
            </div>
            <h2 className="title"> {t("feature2.title")}</h2>
            <p className="lead text-darkSecondary dark:text-light">
              {t("feature2.subtitle")}
            </p>
            <a href="#contact" className="btn-link dark:text-light flex items-center gap-x-3 hover:gap-x-5 transition-all">
              {t("feature2.btnLink")}
              <img src={btnIcon} alt="" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature2;
