import React from "react";
import { useTranslation } from "react-i18next";
import Bg from "../assets/bg.png";
import ProductImg from "../assets/overview/product.png";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <section id="hero" className="h-screen py-0 sm:py-12 lg:py-0 relative">
      <div className="absolute top-0 left-0 h-[calc(100vh)] object-contain w-full z-[-1] bg-red-100">
        <img src={Bg} className="w-full h-full object-fill" />
      </div>
      <div
        data-aos="fade-right"
        data-aos-delay="1600"
        className="container mx-auto h-[calc(100vh-84px)] sm:pb-40 flex justify-center items-center flex-col gap-3 relative"
      >
        <h2 className="text-[10px] sm:text-base xl:text-lg text-gray-400">
          {t("hero.subtitle")}
        </h2>
        <h1 className="text-white text-[2rem] sm:text-[4rem] leading-[70px] xl:text-[5rem] sm:leading-[100px] text-center">
          {t("hero.title")}
        </h1>
        <a
          href="#problem"
          className="text-sm outline-none dark:text-light dark:border-light dark:hover:text-dark hover:border-white sm:text-lg xl:text-xl rounded-full text-white border-2 py-2 px-4 hover:bg-white dark:hover:bg-light dark:hover:border-light hover:text-black transition-all"
        >
          {t("Get Started")}
        </a>
      </div>
      <div
        data-aos="fade-left"
        data-aos-delay="1600"
        className="hidden sm:block container mx-auto"
      >
        <div className="rounded-[2rem] w-full border-[15px] border-[#3D7892] -mt-[120px] md:-mt-[150px] xl:-mt-[180px] xxl:-mt-[255px]">
          <img src={ProductImg} className="rounded-xl" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
