// Header.jsx
import React, { useEffect, useState, useTransition } from "react";
import Logo from "../assets/logo.svg";
import LogoDark from "../assets/logo-dark.svg";
import { HiMenuAlt4, HiOutlineX } from "react-icons/hi";
import Nav from "./Nav";
import MobileNav from "./MobileNav";
import useDarkSide from "../hooks/useDarkSide";
import Switcher from "./Switcher";
import { changeLanguage as changeAppLanguage } from "i18next"; // İsim çakışmasını önlemek için fonksiyon ismini değiştirin
import TurkeyIcon from "../assets/turkey.png";
import EnglishIcon from "../assets/english.png";
import { FaChevronDown } from "react-icons/fa6";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import NavLast from "./NavLast";

const Header = () => {
  const [mobileNav, setMobileNav] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [colorTheme, setTheme] = useDarkSide();
  const { t, i18n } = useTranslation();




  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 40 ? setIsActive(true) : setIsActive(false);
    });
  });

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header
      className={`${
        isActive &&
        "lg:top-0 bg-white dark:bg-dark shadow-xl dark:shadow-[#121212]"
      } py-3 lg:py-3 fixed w-full transition-all z-10`}
    >
      <div className="container mx-auto flex justify-between items-center">
        <a href="#" data-aos="fade-down" data-aos-delay="1000">
            {isActive ? (
              <div className="h-[45px] sm:h-[53.61px]  w-[150px] sm:w-[180px] sm:pb-[.5rem]">
                <img className="h-full object-contain" src={Logo} alt="" />
              </div>
            ) : (
              <div className="h-[45px] sm:h-[53.61px] w-[150px]  sm:w-[180px] sm:pb-[.5rem]">
              <img
                className="w-full h-full object-contain"
                src={LogoDark}
                alt=""
              />
                </div>
            )}
        </a>
        <div
          className="hidden lg:flex"
          data-aos="fade-down"
          data-aos-delay="1200"
        >
          <Nav isActive={isActive} /> 
        </div>
        
        <NavLast isActive={isActive} />
        
        <button
          data-aos="fade-down"
          data-aos-delay="1000"
          className="lg:hidden"
          onClick={() => setMobileNav(!mobileNav)}
        >
          {mobileNav ? (
            <HiOutlineX
              className={`text-3xl  ${
                !isActive ? "text-white" : "text-accent"
              } dark:text-accent`}
            />
          ) : (
            <HiMenuAlt4
              className={`text-3xl ${
                !isActive ? "text-white" : "text-accent"
              } dark:text-accent`}
            />
          )}
        </button>

        <div
          className={`${
            mobileNav ? "left-0" : "-left-full"
          } fixed top-0 bottom-0 w-[60vw] lg:hidden trainsition-all bg-pink-400
         transition-all duration-300`}
        >
          <MobileNav  setMobileNav={setMobileNav} changeLanguage={changeLanguage} isActive={isActive} />
        </div>
      </div>
    </header>
  );
};

export default Header;
