import React, { useState } from "react";
import { team } from "../data";
import { FaLinkedin, FaGithub } from "react-icons/fa6";
import { BiLogoGmail } from "react-icons/bi";
import { useTranslation } from "react-i18next";

const Team = () => {
  const { title, cards } = team;
  const [index, setIndex] = useState(0);
  const { t } = useTranslation();

  return (
    <section id="team" className="section">
      <div className="container mx-auto">
        <h2
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-offset='300'
          className="section-title mb-10 lg:mb-20 text-center"
        >
          {t("team.title")}
        </h2>
        <div className="flex !w-full flex-col lg:flex-row lg:gap-x-[30px] gap-y-[30px] lg:gap-y-0 justify-center items-center">
          {t("team.cards", { returnObjects: true }).map((card, cardIndex) => {
            const { name, delay, role, linkedinUrl, githubUrl } = card;
            const image  = cards[cardIndex].image;
            return (
              <div
                data-aos="fade-up"
                data-aos-delay={delay}
                data-aso-offset={300}
                className="lg:w-full"
                key={cardIndex}
              >
                <div
                  onClick={() => setIndex(cardIndex)}
                  className={`${
                    cardIndex === index
                      ? "bg-white dark:bg-dark dark:shadow-[#000000] shadow-2xl"
                      : "border border-[rgb(240,240,240)] dark:border-none"
                  } flex flex-col items-center justify-center w-[250px] md:w-[220px]  xl:w-full rounded-[12px] p-[40px] cursor-pointer transition-all`}
                >
                  <div className="w-32 h-32 sm:w-40 sm:h-40">
                    <img
                      src={image}
                      className="w-full h-full rounded-full object-cover border-4 border-[#076181] dark:border-none"
                    />
                  </div>
                  <div className="text-lg sm:text-2xl mt-8 sm:mt-12">
                    <h2 className="text-center text-xl xl:text-xxl font-light text-black">
                      {name}
                    </h2>
                  </div>
                  <div className="text-sm md:text-xs xl:text-[14px] font-bold tracking-wide">
                    <p className="dark:text-darkSecondary">{role}</p>
                  </div>
                  <div className="flex flex-row gap-3 mt-8 sm:mt-12">
                    <a
                      href={linkedinUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin
                        className="hover:scale-125 transition-all w-[1.5rem] h-[1.5rem] text-[#076181] dark:text-light"
                      />
                    </a>
                    <a
                      href={githubUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                    <FaGithub
                      className="hover:scale-125 transition-all  w-[1.5rem] h-[1.5rem] text-[#076181] dark:text-light"
                    />
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Team;
