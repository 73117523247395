import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Problem from './components/Problem';
import Feature1 from './components/Feature1';
import Feature2 from './components/Feature2';
import Feature3 from './components/Feature3';
import Team from './components/Team';
import Contact from './components/Contact';
import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 100,
    });
  }, [])
  
  return (
    <div className='overflow-y-scroll overflow-x-hidden !font-custom'>
      <Header/>
      <Hero/>
      <Problem/>
      <Feature1/>
      <Feature2/>
      <Feature3/>
      <Team/>
      <Contact/>
    </div>
  );
}

export default App;
