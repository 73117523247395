import React, { useState, useRef, useEffect } from "react";
import ContactImg from "../assets/contact.svg";
import { BiSolidUser, BiUser } from "react-icons/bi";
import { IoMdMail } from "react-icons/io";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isMessageFocused, setIsMessageFocused] = useState(false);
  const form = useRef();
  const [errorMsg, setErrorMsg] = useState("");
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.length < 3 || name.length > 20) {
      setErrorMsg("Name must be between 3 and 20 characters.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMsg("Invalid email address.");
      return;
    }

    if (message.length < 5) {
      setErrorMsg("Message must be at least 5 characters.");
      return;
    }

    emailjs
      .sendForm(
        "service_yfhqkti",
        "template_jd9mjf1",
        form.current,
        "oJTsWQh7Kw5Lng6Gn"
      )
      .then(
        (result) => {
          setErrorMsg("Email sent successfully !");
        },
        (error) => {
          setErrorMsg(
            "Oopss, it seems we have an error. Please try again later."
          );
        }
      );
    setName("");
    setEmail("");
    setMessage("");
  };

  useEffect(() => {
    setTimeout(() => {
      errorMsg.length > 1 && setErrorMsg("");
    }, 5000);
  }, [errorMsg]);

  return (
    <section id="contact" className="section">
      <div className="container mx-auto">
        <div
          data-aos="fade-up"
          data-aos-offset="0"
          data-aos-delay="400"
          className="flex w-full md:gap-x-[30px] justify-center items-center bg-white dark:bg-dark dark:shadow-[#000000] shadow-2xl rounded-xl p-12 lg:p-20 px-8 lg:px-32"
        >
          <div
            data-aos="fade-right"
            data-aos-offset="0"
            data-aos-delay="600"
            className="hidden md:flex md:flex-1"
          >
            <img src={ContactImg} alt="" />
          </div>
          <div
            data-aos="fade-left"
            data-aos-offset="0"
            data-aos-delay="600"
            className="flex-1"
          >
            <div className="flex transition-all flex-col gap-12">
              <h2 className="h2">{t("contact.title")}</h2>
              <div
                className={`text-accent text-base sm:text-lg font-bold px-3 py-3 z-[12] rounded-xl transition-all left-1/2 transform -translate-x-1/2 duration-300 ${
                  errorMsg ? "top-16 block slide-in-top" : "-top-12 hidden"
                }`}
              >
                {errorMsg}
              </div>
              <div>
                <form ref={form} className="w-full flex flex-col gap-3">
                  <div className="relative w-full">
                    <input
                      className={`bg-[#E6E6E6] dark:bg-[#1F1E26] dark:text-white text-base sm:text-lg rounded-3xl py-2 px-14 w-full placeholder:text-[#AAAAAA] placeholder:font-[600] transition-all duration-300 focus:outline-[#0A5F83] focus:border-[#0A5F81]`}
                      placeholder={isFocused ? "" : t("contact.name")}
                      value={name}
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      disabled={errorMsg ? true : false}
                    />
                    <BiSolidUser
                      className={`absolute  top-[.77rem] left-6 text-[#AAAAAA]  ${
                        isFocused && "left-5 text-accent"
                      } transition-all duration-300`}
                    />
                  </div>
                  <div className="relative w-full">
                    <input
                      className={`bg-[#E6E6E6] dark:bg-[#1F1E26] dark:text-white text-base sm:text-lg rounded-3xl py-2 px-14 w-full placeholder:text-[#AAAAAA] placeholder:font-[600] transition-all duration-300 focus:outline-[#0A5F83] focus:border-[#0A5F81]`}
                      placeholder={isEmailFocused ? "" : t("contact.email")}
                      value={email}
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      onFocus={() => setIsEmailFocused(true)}
                      onBlur={() => setIsEmailFocused(false)}
                      disabled={errorMsg ? true : false}
                    />
                    <IoMdMail
                      className={`absolute  top-[.77rem] left-6 text-[#AAAAAA]  ${
                        isEmailFocused && "left-5 text-accent"
                      } transition-all duration-300`}
                    />
                  </div>
                  <textarea
                    className="bg-[#E6E6E6] dark:bg-[#1F1E26] dark:text-white text-base sm:text-lg resize-none rounded-3xl py-2 px-6 w-full placeholder:text-[#AAAAAA] placeholder:font-[600] transition-all duration-300 focus:outline-[#0A5F83] focus:border-[#0A5F81]"
                    placeholder={isMessageFocused ? "" : t("contact.message")}
                    value={message}
                    name="message"
                    onChange={(e) => setMessage(e.target.value)}
                    onFocus={() => setIsMessageFocused(true)}
                    onBlur={() => setIsMessageFocused(false)}
                    disabled={errorMsg ? true : false}
                  />
                </form>
              </div>
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={errorMsg ? true : false}
                className="bg-[#0A5F81] py-2 text-white rounded-3xl text-base sm:text-lg font-[600] hover:bg-accentHover transition-all duration-300"
              >
                {t("contact.send")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
