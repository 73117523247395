import React, { useState } from 'react';
import useDarkSide from '../hooks/useDarkSide';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

export default function Switcher({isActive}) {
  const [colorTheme, setTheme] = useDarkSide();
  const [darkSide, setDarkSide] = useState(colorTheme === 'light' ? true : false);

  const toggleDarkMode = checked => {
    setTheme(colorTheme);
    setDarkSide(checked);
  };

  return (
    <>
      <div className='h-full flex items-center justify-center text-accent dark:text-accent'> 
        <DarkModeSwitch sunColor={`${isActive ? 'rgb(5,98,129)' : 'white'} `} moonColor={`${isActive ? 'rgb(5,98,129)' : 'white'} `} checked={darkSide} onChange={toggleDarkMode} size={20} />
      </div>
    </>
  );
}