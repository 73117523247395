import React, { useState } from "react";
import Switcher from "./Switcher";
import Language from "./Language";

const NavLast = ({ isActive }) => {

  return (
    <div
      data-aos="fade-down"
      data-aos-delay="1400"
      className="hidden lg:flex gap-6 px-3 py-1 items-center justify-center "
    >
      <Language isActive={isActive}/>
      <Switcher isActive={isActive} />
    </div>
  );
};

export default NavLast;
