import React, { useState } from 'react';

import {nav} from '../data';

import TurkeyIcon from "../assets/turkey.png";
import EnglishIcon from "../assets/english.png";
import { FaChevronDown } from "react-icons/fa6";
import NavLast from './NavLast';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import Language from './Language';

const MobileNav = ({setMobileNav, isActive}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };
  return (
    <div className='bg-accent/95 dark:bg-dark shadow-2xl w-full h-full'>
      <ul className='h-full flex flex-col justify-center items-center gap-y-8'>
        {
            t("navItems", { returnObjects: true }).map((item, index)=>(
                <li key={index}>
                 <a onClick={()=>setMobileNav(false)} className='link text-white text-xl' href={item.href}>{item.name}</a>
                </li>
            ))
        }
        <li>
        <Language mobileNav={true} isActive={isActive}/>

       </li>
      </ul>


    </div>
  )
}

export default MobileNav
