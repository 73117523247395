import React from 'react';
import { features } from '../data';
import { useTranslation } from 'react-i18next';

const Feature3 = () => {
  const { feature3 } = features;
  const { btnIcon, image } = feature3;
  const { t } = useTranslation();
  return (
    <section className='section'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row lg:items-center lg:justify-center lg:gap-x-[30px]'>
        <div className='flex-1' data-aos='fade-right' data-aos-offset='100'>
            <div className='pretitle text-darkSecondary dark:text-light'>
                {t("feature3.pretitle")}
            </div>
            <h2 className='title'>{t("feature3.title")}</h2>
            <p className='lead text-darkSecondary dark:text-light'>{t("feature3.subtitle")}</p>
            <a href="#contact" className="btn-link dark:text-light flex items-center gap-x-3 hover:gap-x-5 transition-all">
{t("feature3.btnLink")} <img src={btnIcon} alt=''/></a>
        </div>
        <div className='flex-1' data-aos='fade-left' data-aos-offset='100'>
            <img src={image} className='' alt='' />
        </div>
        </div>
      </div>
    </section>
  )
}

export default Feature3
