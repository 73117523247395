import HeroImg from "./assets/hero3.svg";
import RedditImage from "./assets/overview/brands/reddit.svg"
import InstagramImage from "./assets/overview/brands/instagram.svg"
import YoutubeImage from "./assets/overview/brands/youtube.svg"
import TiktokImage from "./assets/overview/brands/tiktok.svg"
import OverviewProductImg from './assets/overview/product.svg';
import CardIconImg from './assets/icon1.svg';
import CardIconImg2 from './assets/icon2.svg';
import CardIconImg3 from './assets/icon3.svg';
import Feature1Img from './assets/features/feature1-img.svg'
import Feature2Img from './assets/features/feature2-img.svg'
import Feature3Img from './assets/features/feature3-img.svg'
import ArrowRightImg from './assets/features/arrow-right.svg'
import FatihImg from './assets/fatih.jpeg';
import FurkanImg from './assets/furkan.jpeg';
import OrkunImg from './assets/orkun.jpeg';
import SerhatImg from './assets/serhat.jpeg';

export const nav = [
    { name: 'Hero', href: '#hero' },
    { name: 'Problem', href: '#problem' },
    { name: 'Features', href: '#features' },
    { name: 'Team', href: '#team' },
    { name: 'Contact', href: '#contact' },
  ];



  export const overview = {
    productImg: OverviewProductImg,
    brands: [
      {
        image: RedditImage,
        delay: 300,
      },
      {
        image: InstagramImage,
        delay: 400,
      },
      {
        image: YoutubeImage,
        delay: 500,
      },
      {
        image: TiktokImage,
        delay: 600
      }
    ],
  };


  export const product = {
    title: 'Common problems creators have',
    subtitle:
      'Growing your accounts on social media is a very long process that takes a lot of time and requires you to be active.',
    cards: [
      {
        icon: CardIconImg,
        title: 'Content',
        subtitle: 'What content should I create?',
        delay: 200,
        color: ''
      },
      {
        icon: CardIconImg2,
        title: 'Quantity',
        subtitle:"I can't create more than 2 videos per day",
        delay: 400,
      },
      {
        icon: CardIconImg3,
        title: 'Views',
        subtitle: "Why doesn't anyone watch my videos!",
        delay: 600,
  
      },
    ],
  }


  export const features = {
    feature1: {
      pretitle: 'Time-Saving Video Deployment',
      title: 'Effortless Video Posting',
      subtitle:
        "Don't waste valuable time posting videos on your social media accounts! While others struggle to upload a few, we enable you to upload over 100 videos to any platfrom with just one click.",
      btnLink: 'Learn more',
      btnIcon: ArrowRightImg,
      image: Feature1Img,
    },
    feature2: {
      pretitle: 'Automated Video Creation Solutions',
      title: 'Transformation of Data to Videos',
      subtitle:
        'We streamline the process by transforming specific data obtained from the desired content into videos with a single click. We customize backgrounds, add text, and voiceovers before uploading to any social media platform.',
      btnLink: 'Learn more',
      btnIcon: ArrowRightImg,
      image: Feature2Img,
    },
    feature3: {
      pretitle: 'Streamlined Social Media Management',
      title: 'Automated Growth Solutions',
      subtitle:
        'Our focus lies in leveraging AI for trend analysis, enhancing viewership for accounts, and pinpointing the presently trending content.',
      btnLink: 'Learn more',
      btnIcon: ArrowRightImg,
      image: Feature3Img,
    },
  };

  export const team = {
    title: 'Meet our team',
    cards:[
      {
        name:'Fatih Bahadır',
        role: 'Frontend Developer',
        delay: 300,
        image: FatihImg,
        linkedinUrl: 'https://www.linkedin.com/in/fatihbahadir/',
        githubUrl: 'https://github.com/fatihbahadir',

      },
      {
        name:'Furkan Pınar',
        role: 'Python Developer',
        delay: 500,
        image: FurkanImg,
        linkedinUrl: 'https://www.linkedin.com/in/furkan-pinar/',
        githubUrl: 'https://github.com/furkaanpnr',
      },
      {
        name:'Orkun Kurul',
        role: 'Backend Developer',
        delay: 700,
        image: OrkunImg,
        linkedinUrl: 'https://www.linkedin.com/in/orkun-kurul/',
        githubUrl: 'https://github.com/Razortype',
      },
      {
        name:'Serhat Çelik',
        role: 'Python Developer',
        delay: 900,
        image: SerhatImg,
        linkedinUrl: 'https://www.linkedin.com/in/serhat-celik1/',
        githubUrl: 'https://github.com/SerhatCelikk',
      },

    ]
  }