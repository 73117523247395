import React, { useState } from "react";
import TurkeyIcon from "../assets/turkey.png";
import EnglishIcon from "../assets/english.png";
import i18n from "../i18n";
import { FaChevronDown } from "react-icons/fa6";
import { t } from "i18next";

const Language = ({ mobileNav ,isActive }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`flex gap-2 items-center justify-center cursor-pointer relative ${
        (isActive && !mobileNav) ? "text-accent" :  "text-white dark:text-light"
      }`}
    >
      {i18n.language === "en" ? (
        <>
          <img src={EnglishIcon} className="w-[20px] h-[20px]" />
          <p>EN</p>
        </>
      ) : (
        <>
          <img src={TurkeyIcon} className="w-[20px] h-[20px]" />
          <p>TR</p>
        </>
      )}

      <FaChevronDown className="w-[.75rem] h-[.75rem]" />

      <ul
        className={`absolute top-full left-0 bg-white dark:bg-dark py-6 pr-10 pl-4  justify-start items-center flex flex-col gap-6 rounded shadow-lg ${
          showDropdown ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0'
        } transition duration-300 transform `}
      >
        {t("dropdownItems", { returnObjects: true }).map((item, index) => (
          <li
            key={index}
            onClick={() => {
              changeLanguage(item.id);
              handleMouseLeave();
            }}
            className="flex h-[20px] w-full items-center justify-start gap-5"
          >
            <img
              src={item.id === "en" ? EnglishIcon : TurkeyIcon}
              className="w-[20px] h-[20px] object-cover"
              alt={item.id === "en" ? "English flag" : "Turkish flag"}
            />
            <p className="text-dark dark:text-light">{item.name}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Language;
