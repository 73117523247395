import React, {useState, useTransition} from 'react'
import { product } from '../data';
import ArrowImg from '../assets/arrow.svg';
import { useTranslation } from 'react-i18next';

const Cards = () => {
  const [index, setIndex] = useState(1);
  const { cards } = product;
  const { t } = useTranslation();

  return (
    <div className='flex flex-col w-full gap-y-[30px] lg:flex-row lg:gap-x-[30px]'>
      {
        t("problem.cards", { returnObjects: true }).map((c, cardIndex)=>{
            const icon = cards[cardIndex].icon;
            return <div key={cardIndex} data-aos= 'zoom-out' data-aos-offset='200' data-aos-delay={c.delay}>
                <div 
                onClick={()=> setIndex(cardIndex)}
                className={`${index === cardIndex && 'bg-white dark:bg-dark dark:shadow-[#000000] shadow-2xl'} w-[300px] xl:w-[350px] xxl:w-[450px] xl:h-[300px]
                flex flex-col justify-center items-center mx-auto
                p-[65px] text-center rounded-[12px] cursor-pointer
                transition-all`}>
                    <div className={`mb-6 w-[64px] h-[64px] rounded-full flex items-center justify-center p-3 ${cardIndex === 0 ? 'bg-blue-50' : cardIndex === 1 ? 'bg-green-50' : 'bg-yellow-50'}`}>
                        <img src={icon} className='' alt='' />
                    </div>
                    <div className='mb-3 text-[20px] sm:text-[30px] font-medium dark:text-white'>
                        {c.title}
                    </div>
                    <p className='mb-6 text-[16px] sm:text-[18px] text-darkSecondary dark:text-light'>
                        {c.subtitle}
                    </p>
                    {index === cardIndex && <img src={ArrowImg} />}
                </div>
            </div>
        })
      }
    </div>
  )
}

export default Cards
